<template>
  <div class="limiter">
    <div class="container-login100">
      <div class="wrap-login100">
        <div class="login100-pic js-tilt" data-tilt>
          <img src="@/assets/images/illustrator-login.svg" alt="IMG" />
        </div>

        <form
          class="login100-form validate-form"
          method="post"
          @submit.prevent="loginSubmit"
        >
          <div class="container-logo">
            <img src="@/assets/images/logo.png" alt="LOGO" class="logo" />
          </div>

          <div
            class="form-label-group validate-input"
            data-validate="Valid email is required: ex@abc.xyz"
          >
            <input
              name="userPhoneOrEmail"
              id="userPhoneOrEmail"
              type="text"
              class="form-control"
              placeholder=" "
              maxlength="20"
              v-model="userLogin.user"
            />
            <label for="userPhoneOrEmail">
              {{ $t("userPhoneOrEmail") }}
            </label>
            <img src="@/assets/images/man.svg" />
          </div>

          <div
            class="form-label-group validate-input"
            data-validate="Password is required"
          >
            <input
              name="userPassword"
              id="userPassword"
              v-bind:type="[showPassword ? 'text' : 'password']"
              class="form-control password"
              placeholder=" "
              v-model="userLogin.userPassword"
            />
            <label for="userPassword">{{ $t("password") }}</label>
            <img src="@/assets/images/passwordlock.svg" />
            <span class="toggle-password">
              <img
                :src="
                  require('@/assets/images/eye-' +
                    [showPassword ? 'show' : 'hide'] +
                    '.svg')
                "
                @click="showPassword = !showPassword"
              />
            </span>
          </div>

          <div class="rememberMe-forgotPassword"></div>

          <div class="container-login100-form-btn">
            <button name="login" type="submit" class="login100-form-btn">
              {{ $t("login") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["userLogin"],
  data() {
    return {
      showPassword: false,
      filedType: "password",
    };
  },
  methods: {
    async loginSubmit() {
      this.$emit("login");
    },
    togglePassword() {
      if (this.filedType === "password") {
        this.filedType = "text";
      } else {
        this.filedType = "password";
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="home">
    <PreLoader v-if="isLoading" />

    <LoginForm v-on:login="login()" :userLogin="userLogin" />
  </div>
</template>

<script>
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "./../../components/general/PreLoader.vue";
import { mapGetters } from "vuex";
import { STATUS } from "./../../utils/constants";
import UserLogin from "./../../models/users/UserLogin";
import LoginForm from "./../../components/users/LoginForm.vue";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    LoginForm,
  },
  data() {
    return {
      isLoading: false,
      userLogin: new UserLogin(),
    };
  },
  computed: {
    ...mapGetters(["userHomePage"]),
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        let response = await this.userLogin.login();
        if (response.data.status == STATUS.SUCCESS) {
          let response2 = await this.userLogin.saveUserData(
            response.data.apiAppData
          );
          if (response2.status == STATUS.SUCCESS) {
            this.showMsg(response2.msg, true);
            this.$router.push({ name: this.userHomePage }).catch(() => {});
          } else {
            this.showMsg(response2.msg);
          }
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>

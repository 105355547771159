import { getLanguage } from "./../../utils/functions";
import {
  USER_PLATFORM_SESSION,
  USER_DEVICE,
  ESTABLISHMENT_TOKEN,
  DEFAULT_PAGES,
  STATUS,
} from "./../../utils/constants";
import axios from "axios";
import store from "./../../store";
import i18n from "./../../i18n";

export default class UserLogin {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.language = getLanguage();
    this.user = "";
    this.userPassword = "";
    this.userPlatformSessionData = USER_PLATFORM_SESSION;
    this.userDeviceData = USER_DEVICE;
    this.establishmentToken = ESTABLISHMENT_TOKEN;
  }
  fillData(data) {
    this.language = data.language;
    this.user = data.user;
    this.userPassword = data.userPassword;
    this.userPlatformSessionData = data.userPlatformSessionData;
    this.userDeviceData = data.userDeviceData;
    this.establishmentToken = data.establishmentToken;
  }

  async login() {
    return await axios.post(`/api/Users/UserLogin`, this);
  }

  saveUserData(apiAppData) {
    const privileges = apiAppData.userData.establishmentRoleData;

    // #region userHomePage
    let userHomePage = "";
    if (privileges.usersPrivilegeActivityTypes.viewData === true) {
      userHomePage = "ActivityTypes";
    } else if (privileges.usersPrivilegeBrands.viewData === true) {
      userHomePage = "Brands";
    } else if (privileges.usersPrivilegeBrandServices.viewData === true) {
      userHomePage = "BrandServices";
    } else if (privileges.usersPrivilegeBrandPlaces.viewData === true) {
      userHomePage = "BrandPlaces";
    } else if (privileges.usersPrivilegeBrandUsers.viewData === true) {
      userHomePage = "BrandUsers";
    } else if (privileges.usersPrivilegeSponsors.viewData === true) {
      userHomePage = "Sponsors";
    } else if (privileges.usersPrivilegeOffers.viewData === true) {
      userHomePage = "Offers";
    } else if (privileges.usersPrivilegeCardCreationPlans.viewData === true) {
      userHomePage = "CardPlans";
    } else if (privileges.usersPrivilegeCards.viewData === true) {
      userHomePage = "Cards";
    } else if (privileges.usersPrivilegeSellCards.viewData === true) {
      userHomePage = "SellCards";
    } else if (privileges.usersPrivilegeMasterAdmins.viewData === true) {
      userHomePage = "MasterAdmins";
    } else if (privileges.usersPrivilegeEmployees.viewData === true) {
      userHomePage = "Employees";
    } else if (privileges.usersPrivilegeClients.viewData === true) {
      userHomePage = "Clients";
    } else if (privileges.usersPrivilegeSeller.viewData === true) {
      userHomePage = "Sellers";
    } else if (
      privileges.usersPrivilegeQrCodeSessions.enquiryQrCodeVCD === true
    ) {
      userHomePage = "QrCodeSessionEnquiry";
    } else if (
      privileges.usersPrivilegeQrCodeSessions.generateMyQrCodeVCD === true
    ) {
      userHomePage = "QrCodeSessionGenerate";
    } else if (
      privileges.usersPrivilegeBrands.report === true ||
      privileges.usersPrivilegeCards.report === true ||
      privileges.usersPrivilegeSellCards.report === true
    ) {
      userHomePage = "Reports";
    } else if (
      privileges.usersPrivilegeEstablishmentRoles.viewData === true ||
      privileges.usersPrivilegeUserValidSetting.viewData === true
    ) {
      userHomePage = "Settings";
    } else {
      userHomePage = DEFAULT_PAGES.notLoggedIn;
    }
    store.dispatch("setUserHomePage", userHomePage);
    // #endregion userHomePage

    let response = {
      status: STATUS.ERROR,
      msg: i18n.t("sorryYouDoNotHaveThisPermission"),
    };
    if (userHomePage != DEFAULT_PAGES.notLoggedIn) {
      store.dispatch("loginUser", apiAppData);
      response.status = STATUS.SUCCESS;
      response.msg = i18n.t("successOperation");
    }
    return response;
  }
}
